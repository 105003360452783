export default [
  { key: "Dashboard" },
  { key: "Authenticate" },
  { key: "EmailRegisterReceive" },
  { key: "FileManager" },
  { key: "LandingPage" },
  { key: "Template" },
  { key: "Pattern" },
  { key: "User" },
  { key: "VMindmap" },
  { key: "GroupCode" },
  { key: "Options" },
  { key: "Ticket" },
  { key: "LogoConfig" },
  { key: "BackgroundManager" },
];
