import {
  ticket,
  ticket_category,
  ticket_category_select,
  user_select,
} from "@Modules/Ticket/configs/constants";

import list_ticket from "./reducers";
import list_ticket_category from "./reducers/category";
import list_ticket_category_select from "./reducers/cateSelect";
import list_user_select from "./reducers/userSelect";

export default {
  [ticket]: list_ticket,
  [ticket_category]: list_ticket_category,
  [ticket_category_select]: list_ticket_category_select,
  [user_select]: list_user_select,
};
