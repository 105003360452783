import {
  vmindmap,
  vmindmap_category,
  vmindmap_category_select,
} from "@Modules/VMindmap/configs/constants";

import list_vmindmap from "./reducers";
import list_vmindmap_category from "./reducers/category";
import list_vmindmap_category_select from "./reducers/categorySelect";

export default {
  [vmindmap]: list_vmindmap,
  [vmindmap_category]: list_vmindmap_category,
  [vmindmap_category_select]: list_vmindmap_category_select,
};
