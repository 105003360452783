import { lazy } from "react";

// ** Document title
const TemplateTitle = "%s - Admin";

// ** Default Route
const DefaultRoute = "/home";

// ** Merge Routes
const Routes = [
  {
    path: "/error",
    component: lazy(() => import("../../views/Error")),
    layout: "BlankLayout",
  },
  {
    path: "/coming-soon",
    component: lazy(() => import("../../views/ComingSoon")),
    layout: "VerticalLayout",
  },
];

export { DefaultRoute, TemplateTitle, Routes };
