import { put, call, takeEvery } from 'redux-saga/effects';
import * as Actions from './constants';
import { getListCity, getListDistrict } from './services';
import { RETCODE_SUCCESS, SUCCESS } from '@configs/contants';

/**
 * Fetch data saga
 * @returns {IterableIterator<*>}
 */
function* fetchProvince() {
  try {
    const res = yield call(getListCity);
    const { data } = res;
    if (res.retCode === RETCODE_SUCCESS) {
      yield put({ type: Actions.SET_BASIC_DATA_SUCCESS, province: data });
    } else {
      yield put({ type: Actions.ERROR, error: data.retText });
    }
  } catch (e) {
    yield put({ type: Actions.ERROR, error: e });
  }
}

function* fetchDistrict({ payload }) {
  try {
    const res = yield call(getListDistrict, { ProvinceId: payload });
    const { data } = res;
    if (res.retCode === RETCODE_SUCCESS) {
      yield put({ type: Actions.SET_BASIC_DATA_SUCCESS, district: data });
    } else {
      yield put({ type: Actions.ERROR, error: data.retText });
    }
  } catch (e) {
    yield put({ type: Actions.ERROR, error: e });
  }
}

export default function* commonSaga() {
  yield takeEvery(Actions.GET_PROVICE, fetchProvince);
  yield takeEvery(Actions.GET_DISTRICT, fetchDistrict);
}
