const namespace = "langding_page";

export const langding_page = `${namespace}_list`;
export const langding_page_category = `${namespace}_category_list`;
export const langding_page_category_select = `${namespace}_category_select`;
export const langding_page_select = `${namespace}_select`;
export const langding_pageSelectData = `${namespace}_select_data`;

export const language_select = `${namespace}_language_select`;
export const module_select = `${namespace}_module_select`;
