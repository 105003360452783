import intlMessagesEN from "./i18n/localization/en.json"
import intlMessagesVi from "./i18n/localization/vi.json"
import initReducer from "./Store/initReducer"

export default {
  name: "BackgroundManager",
  dir: "BackgroundManager",
  pathRoot: "background-manager",
  routes: [
    {
      url: "",
      component: "Page/BackgroundManager",
      meta: {
        authRoute: true
      },
      props: {
        title: "Hình ảnh | VMindmap Tâm Trí Lực",
        titleI18n: "BackgroundManager:titleTab"
      }
    }
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer
}
