import API from "../../configs/api";
import apiSTNHDMethod from "@utility/ApiSTNHDMethod";

import list_users from "@assets/json/users.json";
import list_categories from "@assets/json/categories.json";

export const getListUserSTNHĐ = () => {
  return Promise.resolve(list_users);
};

export const getListCategorySTNHĐ = () => {
  return Promise.resolve(list_categories);
};

export const getListCity = async (payload) => {
  try {
    const { data } = await apiSTNHDMethod.get(`${API.GET_COMMON_GET_CITY}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListDistrict = async (payload) => {
  try {
    const { data } = await apiSTNHDMethod.get(
      `${API.GET_COMMON_GET_DISTRICT}`,
      {
        params: payload,
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
