import {
  email_register_receive,
  email_register_receive_select,
} from "@Modules/EmailRegisterReceive/configs/constants";

import list_email_register_receive from "./reducers";
import list_email_register_receive_select from "./reducers/emailSelect";

export default {
  [email_register_receive]: list_email_register_receive,
  [email_register_receive_select]: list_email_register_receive_select,
};
