import {
  template,
  template_category,
  template_category_select,
  mindmap_select,
} from "@Modules/Template/configs/constants";

import list_template from "./reducers";
import list_template_category from "./reducers/category";
import list_template_category_select from "./reducers/categorySelect";
import list_mindmap_select from "./reducers/mindmapSelect";

export default {
  [template]: list_template,
  [template_category]: list_template_category,
  [template_category_select]: list_template_category_select,
  [mindmap_select]: list_mindmap_select,
};
