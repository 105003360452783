export const defaultValue = {
  listIds: [],
  list: [],
  hash: {},
  pagination: {
    curPage: 1,
    limitPage: 50,
    offset: 50,
    start: 0,
    startIndex: 0,
    totalPage: 0,
    totalRows: 0,
    pageSizeOptions: ["5", "10", "20", "50", "100", "200", "500"],
    showSizeChanger: true,
  },
  sorter: {},
  filters: {},
};

export const select_identity_table_dynamic_key = (identity, key) => (state) => {
  return state[identity][key] || defaultValue;
};

export const get_data_from_identity = (identity) => (state) => {
  return state[identity] || {};
};
