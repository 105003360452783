import API from "@Modules/Dashboard/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getUserSummary = (payload) => {
  return apiMethod.get(`${API.GET_USER_SUMMARY}`, {
    params: payload,
  });
};

export const getDashboardReport = async (payload) => {
  try {
    const res = await apiMethod.get(`${API.GET_DASHBOARD_REPORT}`, {
      params: payload,
    });
    const results = {
      data: {
        ...res.data.data.listTicket,
        extendsData: res.data.data.dashboard,
      },
      retCode: res.data.retCode,
      retText: res.data.retText,
    };
    return Promise.resolve({ ...res, data: results });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const exportUserBirthday = async (payload) => {
  try {
    const res = await apiMethod.get(`${API.EXPORT_USER_BIRTHDAY}`, {
      params: payload,
      responseType: "blob",
    });
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getNewUsers = (payload) => {
  return apiMethod.get(`${API.GET_NEW_USERS}`, {
    params: payload,
  });
};

export const exportNewUser = async (payload) => {
  try {
    const res = await apiMethod.get(`${API.EXPORT_NEW_USER}`, {
      params: payload,
      responseType: "blob",
    });
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserWasInvited = (payload) => {
  return apiMethod.get(`${API.GET_USER_WAS_INVITED}`, {
    params: payload,
  });
};

export const exportUserWasInvited = async (payload) => {
  try {
    const res = await apiMethod.get(`${API.EXPORT_USER_WAS_INVITED}`, {
      params: payload,
      responseType: "blob",
    });
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTopPoint = (payload) => {
  return apiMethod.post(`${API.GET_TOP_POINT}`, payload);
};

export const getReportTopPoint = (payload) => {
  return apiMethod.get(`${API.GET_TOP_REPORT_POINT}`, { params: payload });
};
