import { get_action_data_select } from "./reducers";

export const GET_DATA_FOR_SELECT_REQUEST = "GET_DATA_FOR_SELECT_REQUEST";
export const GET_DATA_FOR_SELECT_SUCCESS = "GET_DATA_FOR_SELECT_SUCCESS";
export const GET_DATA_FOR_SELECT_FAILURE = "GET_DATA_FOR_SELECT_FAILURE";
export const SET_DEFAULT_IDENTITY_DATA_SELECT_SUCCESS =
  "SET_DEFAULT_IDENTITY_DATA_SELECT_SUCCESS";
export const CLEAR_IDENTITY_DATA_SELECT = "CLEAR_IDENTITY_DATA_SELECT";
export const ADD_DATA_FOR_SELECT_SUCCESS = "ADD_DATA_FOR_SELECT_SUCCESS";
export const UPDATE_DATA_FOR_SELECT_SUCCESS = "UPDATE_DATA_FOR_SELECT_SUCCESS";

//Load more
export const GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST =
  "GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST";
export const GET_DATA_LOAD_MORE_FOR_SELECT_SUCCESS =
  "GET_DATA_LOAD_MORE_FOR_SELECT_SUCCESS";
export const GET_DATA_LOAD_MORE_FOR_SELECT_FAILED =
  "GET_DATA_LOAD_MORE_FOR_SELECT_FAILED";

export function get_data_for_select_action(
  identity,
  api,
  current,
  pageSize = 50,
  filter,
  dynamicKey,
  treeMode,
  treeKey
) {
  return {
    type: GET_DATA_FOR_SELECT_REQUEST,
    payload: {
      identity,
      api,
      current,
      pageSize,
      filter,
      dynamicKey,
      treeMode,
      treeKey,
    },
  };
}

export function set_data_for_select_action(key, list) {
  return {
    type: GET_DATA_FOR_SELECT_SUCCESS,
    payload: { identity, list },
  };
}

export function set_default_identity_data_select_action(
  identity,
  list,
  treeMode
) {
  return {
    type: get_action_data_select(
      identity,
      SET_DEFAULT_IDENTITY_DATA_SELECT_SUCCESS
    ),
    payload: { list, treeMode },
  };
}

export function clear_identity_data_select(identity, dynamicKey) {
  return {
    type: get_action_data_select(identity, CLEAR_IDENTITY_DATA_SELECT),
    payload: { dynamicKey },
  };
}

export function add_identity_data_success(identity, res, dynamicKey) {
  return {
    type: get_action_data_select(identity, ADD_DATA_FOR_SELECT_SUCCESS),
    payload: { res, dynamicKey },
  };
}

export function update_identity_data_success(
  identity,
  recordUpdate = {
    id: "",
    column: "",
    data: "",
    rowMode: false,
    extendsData: null,
  },
  dynamicKey
) {
  return {
    type: get_action_data_select(identity, UPDATE_DATA_FOR_SELECT_SUCCESS),
    payload: { ...recordUpdate, dynamicKey },
  };
}

//Load more
export function get_data_load_more_for_select_action(
  identity,
  api,
  current,
  pageSize = 50,
  filter,
  dynamicListKey,
  paginationKey
) {
  // console.log("more", current, pageSize);
  return {
    type: GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST,
    payload: {
      identity,
      api,
      current,
      pageSize,
      filter,
      dynamicListKey,
      paginationKey,
    },
  };
}
