const namespace = "template";

export const template = `${namespace}_list`;
export const template_category = `${namespace}_category_list`;
export const mindmap_select = `${namespace}_mindmap_select`;
export const template_category_select = `${namespace}_category_select`;
export const user_select = `${namespace}_user`;
export const templateSelect = `${namespace}_select`;
export const templateSelectData = `${namespace}_select_data`;
export const typeSelect = `${namespace}_type_select`;

