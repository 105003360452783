import {
  langding_page,
  language_select,
  module_select,
} from "@Modules/LandingPage/configs/constants";

import list_langding_page from "./reducers";
import list_language_select from "./reducers/languageSelect";
import list_module_select from "./reducers/moduleSelect";

export default {
  [langding_page]: list_langding_page,
  [language_select]: list_language_select,
  [module_select]: list_module_select,
};
