import { takeEvery, call, put, all } from "redux-saga/effects";
import { get_action_data_select } from "./reducers";
/* Actions */
import * as actions from "./actions";

/*Constants*/
import { RETCODE_SUCCESS } from "@configs/contants";

function* get_data_for_select_worker({ payload }) {
  const {
    identity,
    api,
    current = 1,
    pageSize = 50,
    filter,
    dynamicKey = "listData",
    treeMode,
    treeKey,
  } = payload;

  const opts = {
    page: current,
    limit: pageSize,
    ...filter,
  };

  yield put({
    type: get_action_data_select(identity, actions.GET_DATA_FOR_SELECT_REQUEST),
  }); // Trigger loading

  try {
    const res = yield call(api, opts);
    const { data } = res;

    if (data.retCode === RETCODE_SUCCESS) {
      let payload = { list: [], pagination: {} };
      if (Array.isArray(data.data)) {
        payload = {
          list: data.data,
          treeMode,
          treeKey,
          pagination: {
            curPage: current,
            limitPage: pageSize,
            treeMode,
            treeKey,
          },
          ...filter,
        };
      } else {
        const resData = data.data;
        payload = {
          list: resData[dynamicKey],
          pagination: resData.paging,
          treeMode,
          treeKey,
          ...filter,
        };
      }
      yield put({
        type: get_action_data_select(
          identity,
          actions.GET_DATA_FOR_SELECT_SUCCESS
        ),
        payload,
      });
    }
  } catch (error) {
    console.error(error.response?.data);
    console.error("Có lỗi xảy ra, không thể lấy dữ liệu");
    yield put({ type: actions.GET_DATA_FOR_SELECT_FAILURE });
  }
}

function* get_data_load_more_for_select_worker({ payload }) {
  const {
    identity,
    api,
    current = 1,
    pageSize = 50,
    filter,
    dynamicListKey = "listData",
    paginationKey = "paging",
  } = payload;
  const opts = {
    page: current,
    limit: pageSize,
    ...filter,
  };

  yield put({
    type: get_action_data_select(
      identity,
      actions.GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST
    ),
  }); // Trigger loading

  try {
    const res = yield call(api, opts);
    const { data } = res;
    let payload = {
      list: [],
      pagination: { current, pageSize },
    };
    if (Array.isArray(data?.data)) {
      payload = {
        list: data?.data,
        pagination: { current, pageSize },
      };
    } else {
      payload = {
        list: data.data ? data.data[dynamicListKey] : data[dynamicListKey],
        pagination: data.data ? data.data[paginationKey] : data[paginationKey],
      };
    }

    yield put({
      type: get_action_data_select(
        identity,
        actions.GET_DATA_LOAD_MORE_FOR_SELECT_SUCCESS
      ),
      payload,
    });
  } catch (error) {
    console.error(error.response?.data);
    console.error("Có lỗi xảy ra, không thể lấy dữ liệu");
    yield put({ type: actions.GET_DATA_LOAD_MORE_FOR_SELECT_FAILED });
  }
}

export function* get_data_for_select_watcher() {
  yield takeEvery(
    actions.GET_DATA_FOR_SELECT_REQUEST,
    get_data_for_select_worker
  );
}

export function* get_data_load_more_for_select_watcher() {
  yield takeEvery(
    actions.GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST,
    get_data_load_more_for_select_worker
  );
}

export default function* () {
  yield all([
    get_data_for_select_watcher(),
    get_data_load_more_for_select_watcher(),
  ]);
}
