import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "VMindmap",
  dir: "VMindmap",
  pathRoot: "vmindmap",
  routes: [
    {
      url: "category/:id",
      component: "Page/Category/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật danh mục tin tức | eLearning Tâm Trí Lực",
        titleI18n: "VMindmap:updateCategoryTab",
      },
    },
    {
      url: "category",
      component: "Page/Category",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh mục tin tức | eLearning Tâm Trí Lực",
        titleI18n: "VMindmap:titleCategoryTab",
      },
    },
    {
      url: ":id",
      component: "Page/VMindmap/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Tin tức | eLearning Tâm Trí Lực",
        titleI18n: "VMindmap:updateTab",
      },
    },
    {
      url: "",
      component: "Page/VMindmap",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Tin tức | eLearning Tâm Trí Lực",
        titleI18n: "VMindmap:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
