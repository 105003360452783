import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Template",
  dir: "Template",
  pathRoot: "template",
  routes: [
    {
      url: "category/:id",
      component: "Page/Category/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật danh mục template | VMindmap Tâm Trí Lực",
        titleI18n: "Template:updateCategoryTab",
      },
    },
    {
      url: "category",
      component: "Page/Category",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh mục template | VMindmap Tâm Trí Lực",
        titleI18n: "Template:titleCategoryTab",
      },
    },
    {
      url: ":id",
      component: "Page/Template/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Template | VMindmap Tâm Trí Lực",
        titleI18n: "Template:updateTab",
      },
    },
    {
      url: "",
      component: "Page/Template",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Template | VMindmap Tâm Trí Lực",
        titleI18n: "Template:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
