import {
  logo_config,
  logo_config_category,
  logo_config_category_select,
  user_select,
} from "@Modules/LogoConfig/configs/constants";

import list_logo_config from "./reducers";
import list_config_category from "./reducers/category";
import list_config_category_select from "./reducers/cateSelect";
import list_user_select from "./reducers/userSelect";

export default {
  [logo_config]: list_logo_config,
  [logo_config_category]: list_config_category,
  [logo_config_category_select]: list_config_category_select,
  [user_select]: list_user_select,
};
