import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Options",
  dir: "Options",
  pathRoot: "options",
  routes: [
    {
      url: "ticket-category/:id",
      component: "Page/CreateEdit/TicketCategory",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật danh mục | V.Mindmap",
        titleI18n: "Options:updateTabTicketCategory",
      },
    },
    {
      url: "ticket-category",
      component: "Page/Table/TicketCategory",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh mục ticket | V.Mindmap",
        titleI18n: "Options:titleTabTicketCategory",
      },
    },
    {
      url: "service/:id",
      component: "Page/CreateEdit/Private",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật điều khoản dịch vụ | V.Mindmap",
        titleI18n: "Options:updateTabService",
      },
    },
    {
      url: "service",
      component: "Page/Table/Service",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Điều khoản dịch vụ | V.Mindmap",
        titleI18n: "Options:titleTabService",
      },
    },
    {
      url: "private/:id",
      component: "Page/CreateEdit/Private",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật chính sách bảo mật | V.Mindmap",
        titleI18n: "Options:updateTabPrivate",
      },
    },
    {
      url: "private",
      component: "Page/Table/Private",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Chính sách bảo mật | V.Mindmap",
        titleI18n: "Options:titleTabPrivate",
      },
    },
    {
      url: "faq/:id",
      component: "Page/CreateEdit/Faq",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật câu hỏi thường gặp | V.Mindmap",
        titleI18n: "Options:updateTabFaq",
      },
    },
    {
      url: "faq",
      component: "Page/Table/Faq",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Câu hỏi thường gặp | V.Mindmap",
        titleI18n: "Options:titleTabFaq",
      },
    },
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Loại | V.Mindmap",
        titleI18n: "Options:updateTab",
      },
    },
    {
      url: "",
      component: "Page/Table",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Loại | V.Mindmap",
        titleI18n: "Options:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  role: ["admin"],
};
