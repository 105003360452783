import {
  group_code,
  group_code_select,
} from "@Modules/GroupCode/configs/constants";

import list_group_code from "./reducers";
import list_group_code_select from "./reducers/groupCodeSelect";

export default {
  [group_code]: list_group_code,
  [group_code_select]: list_group_code_select,
};
