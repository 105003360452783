const namespace = "options";

export const options = `${namespace}_list`;
export const options_category = `${namespace}_category_list`;
export const options_category_select = `${namespace}_category_select`;
export const optionsSelect = `${namespace}_select`;
export const optionsSelectData = `${namespace}_select_data`;

export const optionsFaq = `${namespace}_faq_list`;
export const optionsArea = `${namespace}_area_list`;
export const optionsPrivate = `${namespace}_private_list`;
export const optionsService = `${namespace}_service_list`;
export const optionsTicketCategory = `${namespace}_ticket_list`;

export const optionsTypeRef = `${namespace}_type_ref_list`;
export const optionsRoleRef = `${namespace}_role_ref_list`;
export const optionsGroupRef = `${namespace}_group_ref_list`;

export const optionsCriteriaGroup = `${namespace}_criteria_group_list`;
