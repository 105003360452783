import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Pattern",
  dir: "Pattern",
  pathRoot: "pattern",
  routes: [
    {
      url: ":id",
      component: "Page/CreateEdit",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Họa tiết nhánh | VMindmap Tâm Trí Lực",
        titleI18n: "Pattern:title",
        exact: true,
      },
    },
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Họa tiết nhánh | VMindmap Tâm Trí Lực",
        titleI18n: "Pattern:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
