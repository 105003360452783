import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "LogoConfig",
  dir: "LogoConfig",
  pathRoot: "",
  routes: [

    {
      url: "logo-config",
      component: "Page/Ticket",
      meta: {
        authRoute: true,
      },
      props: {
        title: "LogoConfig | V.Mindmap",
        titleI18n: "LogoConfig:titleTab",
      },
    },
    {
      url: "logo-config-video",
      component: "Page/Ticket/ConfigLogoVideoPage",
      meta: {
        authRoute: true,
      },
      props: {
        title: "LogoConfig Video | V.Mindmap",
        titleI18n: "LogoConfig:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  role: ["admin"],
};
