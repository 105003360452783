import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Ticket",
  dir: "Ticket",
  pathRoot: "ticket",
  routes: [
    {
      url: "category/:id",
      component: "Page/Category/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật danh mục ticket | V.Mindmap",
        titleI18n: "Ticket:updateCategoryTab",
      },
    },
    {
      url: "category",
      component: "Page/Category",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh mục ticket | V.Mindmap",
        titleI18n: "Ticket:titleCategoryTab",
      },
    },
    {
      url: ":id",
      component: "Page/Ticket/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Ticket | V.Mindmap",
        titleI18n: "Ticket:updateTab",
      },
    },
    {
      url: "",
      component: "Page/Ticket",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Ticket | V.Mindmap",
        titleI18n: "Ticket:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  role: ["admin"],
};
