import {
  images,
  images_category,
  images_category_select,
  images_select,
} from "@Modules/FileManager/configs/constants";

import list_images from "./reducers";
import list_images_category from "./reducers/category";
import list_images_category_select from "./reducers/categorySelect";
import list_images_select from "./reducers/imageSelect";

export default {
  [images]: list_images,
  [images_category]: list_images_category,
  [images_category_select]: list_images_category_select,
  [images_select]: list_images_select,
};
