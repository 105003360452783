import {
  options,
  options_category,
  options_category_select,
  optionsArea,
  optionsFaq,
  optionsPrivate,
  optionsService,
  optionsTypeRef,
  optionsRoleRef,
  optionsGroupRef,
  optionsCriteriaGroup,
  optionsTicketCategory,
} from "@Modules/Options/configs/constants";

import list_options from "./reducers";
import list_options_category from "./reducers/category";
import list_options_category_select from "./reducers/optionsSelect";
import list_options_faq from "./reducers/faq";
import list_options_area from "./reducers/area";
import list_options_type_ref from "./reducers/typeRef";
import list_options_role_ref from "./reducers/roleRef";
import list_options_group_ref from "./reducers/groupRef";
import list_options_criteria_group from "./reducers/criteriaGroup";
import list_options_ticket_category from "./reducers/ticketCategory";

import list_options_private from "./reducers/private";
import list_options_service from "./reducers/service";

export default {
  [options]: list_options,
  [options_category]: list_options_category,
  [options_category_select]: list_options_category_select,
  [optionsArea]: list_options_area,
  [optionsFaq]: list_options_faq,
  [optionsTypeRef]: list_options_type_ref,
  [optionsRoleRef]: list_options_role_ref,
  [optionsGroupRef]: list_options_group_ref,
  [optionsCriteriaGroup]: list_options_criteria_group,
  [optionsTicketCategory]: list_options_ticket_category,
  [optionsPrivate]: list_options_private,
  [optionsService]: list_options_service,
};
