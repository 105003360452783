import API from "@Modules/Authenticate/configs/api";
import apiMethod from "@utility/ApiMethod";
import apiSTNHDMethod from "@utility/ApiSTNHDMethod";

export const signIn = async (payload) => {
  try {
    const { data } = await apiMethod.post(API.AUTHENTICATE_LOGIN, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const syncInfo = async (payload) => {
  try {
    const { data } = await apiMethod.post(API.SYNC_INFO, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const sendOtp = ({ codeLanguage, payload }) => {
  return apiMethod.post(codeLanguage + API.POST_SEND_OTP, {
    phoneNumber: payload,
  });
};
